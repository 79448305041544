import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Box,
    Tooltip,
} from '@mui/material';
// components
import Scrollbar from '../components/scrollbar';
// icons
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MoveUpIcon from '@mui/icons-material/MoveUp';
// sections
import {
    WalletListHead,
} from 'src/sections/@dashboard/wallets';
// api
import { getWallets } from 'src/api/wallets/getWallets';
import { isoToReadableDate } from 'src/utils/isoToReadableDate';
import { CategoryListToolbar } from 'src/sections/@dashboard/categories';

// ----------------------------------------------------------------------

// IDS SHOULD BE EQUAL TO PROPERTIES IN THE OBJECTS
const TABLE_HEAD = [
    { id: 'username', label: 'Пользователь', align: 'left' },
    { id: 'balance', label: 'Баланс', align: 'left' },
    { id: 'pendingBalance', label: 'Ожидаемый баланс', align: 'left' },
    { id: 'pendingWithdrawals', label: 'Ожидаемые выводы', align: 'left' },
    { id: 'lastUpdated', label: 'Обновлён', align: 'left' },
    { id: '' },
];

// ----------------------------------------------------------------------

export default function WalletsPage() {

    const [open, setOpen] = useState(null);

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('desc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('lastUpdated');

    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [wallets, setWallets] = useState(null);

    const [walletsTotal, setWalletsTotal] = useState(0);

    useEffect(() => {
        setWallets(null);
        setSelected([]);
        const getWalletsData = async () => {
            try {
                const data = await getWallets(
                    rowsPerPage,
                    page,
                    orderBy,
                    order
                );
                if (data?.data?.wallets) {
                    setWallets(data.data.wallets);
                }
                if (data?.data?.totalWallets) {
                    setWalletsTotal(data.data.totalWallets);
                }
            } catch (error) {
                setWallets(null);
                setSelected([]);
            }
        };
        getWalletsData();
    }, [rowsPerPage, page, orderBy, order]);

    const handleOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleRequestSort = (event, property) => {
        setWallets(null);
        setSelected([]);
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setWallets(null);
        setSelected([]);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setWallets(null);
        setSelected([]);
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - walletsTotal) : 0;

    const isNotFound = false;

    const handleSearch = async (searchValue) => {
        setWallets(null);
        setSelected([]);
        setPage(0);
        try {
            let data = null;
            if(searchValue.length >= 3) {
                data = await getWallets(rowsPerPage, page, orderBy, order, searchValue)
            } else if(searchValue.length === 0) {
                data = await getWallets(rowsPerPage, page, orderBy, order)
            }
            if (data?.data?.wallets) {
                setWallets(data.data.wallets);
            }
            if (data?.data?.totalWallets) {
                setWalletsTotal(data.data.totalWallets);
            }
            
        } catch (error) {
            setWallets(null);
            setSelected([]);
        }
    }

    return (
        <>
            <Helmet>
                <title> Wallets | Mentup Dashboard </title>
            </Helmet>

            <Container>

                <Card>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mr: 3,
                    }}>
                        <CategoryListToolbar
                            handleSearchGlobal={handleSearch}
                            title={'кошелька'}
                        />
                    </Box>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <WalletListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={wallets?.length || 0}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>

                                    {wallets?.map((wallet, index) => {
                                            const selectedWallet = selected.indexOf(wallet._id) !== -1;

                                            return (
                                                <TableRow
                                                    hover
                                                    key={index}
                                                    tabIndex={-1}
                                                    role='checkbox'
                                                    selected={selectedWallet}
                                                >
                                                   <TableCell
                                                        component='th'
                                                        scope='row'
                                                        padding='none'
                                                        sx={{
                                                            paddingLeft: '1rem',
                                                        }}
                                                    >
                                                        <Stack
                                                            direction='row'
                                                            alignItems='center'
                                                            spacing={2}
                                                        >
                                                            {wallet.user?.avatar ? (
                                                                <Tooltip title="Copy user id" arrow>
                                                                    <Avatar
                                                                        alt={
                                                                            wallet.user.username
                                                                                ? wallet.user.username
                                                                                : 'User avatar'
                                                                        }
                                                                        src={wallet.user.avatar}
                                                                        onClick={() => navigator.clipboard.writeText(wallet.user._id || '')}
                                                                        sx={{
                                                                            ':hover': {
                                                                                cursor: 'pointer'
                                                                            }
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                            ) : (
                                                                <Tooltip title="Copy user id" arrow>
                                                                    <AccountCircleIcon
                                                                        sx={{
                                                                            height: '2.5rem',
                                                                            width: '2.5rem',
                                                                            ':hover': {
                                                                                cursor: 'pointer'
                                                                            }
                                                                        }}
                                                                        onClick={() => navigator.clipboard.writeText(wallet.user._id || '')}
                                                                    />
                                                                </Tooltip>
                                                            )}

                                                        <Typography
                                                            sx={{
                                                                ':hover': {
                                                                    cursor: 'pointer',
                                                                    textDecoration: 'underline'
                                                                }
                                                            }}
                                                            onClick={() => window.open('/dashboard/user/' + wallet.user._id, '_blank')}
                                                        >
                                                            {wallet.user?.username || ''}
                                                        </Typography>

                                                        </Stack>
                                                    </TableCell>

                                                    <TableCell align='left'>
                                                        <Typography>
                                                            {`${`${wallet?.balance.toFixed(2)}`.replace(/\d(?=(\d{3})+\.)/g, "$&,")} ₽`}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell align='left'>
                                                        <Typography>
                                                            {`${`${wallet?.pendingBalance.toFixed(2)}`.replace(/\d(?=(\d{3})+\.)/g, "$&,")} ₽`}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell align='left'>
                                                        <Typography>
                                                            {`${`${wallet?.pendingWithdrawals.toFixed(2)}`.replace(/\d(?=(\d{3})+\.)/g, "$&,")} ₽`}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell align='left'>
                                                        {wallet.updatedAt ? (
                                                            <Typography>
                                                                {isoToReadableDate(
                                                                    wallet.updatedAt
                                                                )}
                                                            </Typography>
                                                        ) : null}
                                                    </TableCell>

                                                    <TableCell align="right">
                                                        <Tooltip title="View Transactions" arrow>
                                                            <IconButton
                                                                size="small" // Makes the icon smaller
                                                                color="inherit"
                                                                onClick={() => window.open('/dashboard/transactions?user=' + wallet.user.username, '_blank')}
                                                            >
                                                                <MoveUpIcon sx={{ fontSize: '16px' }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>

                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{ height: 53 * emptyRows }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell
                                                align='center'
                                                colSpan={6}
                                                sx={{ py: 3 }}
                                            >
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography
                                                        variant='h6'
                                                        paragraph
                                                    >
                                                        Не найдено
                                                    </Typography>

                                                    <Typography variant='body2'>
                                                    Не найдено
                                                        &nbsp;
                                                        <strong>
                                                            &quot;
                                                            {'Search Value'}
                                                            &quot;
                                                        </strong>
                                                        .
                                                        <br /> Попробуйте проверить опечатки или корректируйте запрос.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        labelRowsPerPage="Выводить по:"
                        rowsPerPageOptions={[10, 25]}
                        component='div'
                        count={walletsTotal && walletsTotal}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>

        </>
    );
}
