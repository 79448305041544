import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material';
import { nanoid } from 'nanoid';

// mui
import {
    Container,
    Stack,
    Typography,
    Box,
    TextField,
    MenuItem,
    FormHelperText,
    Button,
    Select,
    FormControl,
    InputLabel,
    IconButton,
    Snackbar,
    Alert,
} from '@mui/material';

// form
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// icons
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';

// api
import { getUser } from 'src/api/users/getUser';
import { changeUserStatus, updateUser } from 'src/api/users/updateUser';

function UserEditPage() {

    const { id } = useParams();

    const theme = useTheme();

    const [userData, setUserData] = useState(null);

    const [submittedFormData, setSubmittedFormData] = useState(null);

    const [userEditedNotification, setUserEditedNotification] = useState(null);

    const [success, setSuccess] = useState(false);

    const [successMessage, setSuccessMessage] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        resetAll();
        const getUserData = async (id) => {
            try {
                const res = await getUser(id);
                if (res?.data) {
                    setUserData(res.data.user);
                    console.log(res.data)
                }
            } catch (error) {
                console.log(error)
                //notifyUserEdited('error');
                navigate('/dashboard/user');
            }
        };
        if (id) {
            getUserData(id);
        }
    }, [id]);

    useEffect(() => {
        if (submittedFormData) {
            const sendUpdatedUserData = async () => {
                try {
                    if (userData && userData._id) {
                        const data = await updateUser(
                            {...submittedFormData},
                            userData._id
                        );

                        if (data?.data) {
                            console.log(data.data);
                            //notifyUserEdited('edit');
                        }
                    }
                } catch (error) {
                    console.log(error);
                    //notifyUserEdited('error');
                }
            };
            sendUpdatedUserData();
        }
    }, [submittedFormData,]);

    // Display success message when success message is set
    useEffect(() => {
        if (successMessage) {
            handleSuccessOpen();
        }
    }, [successMessage]);

    const schema = yup.object().shape({
        username: yup.string().min(3).max(20).required(),
        email: yup.string().email().required(),
        role: yup.string().oneOf(['admin', 'vendor', 'buyer', 'moderator', 'support', 'marketing']).required(),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const submitUserData = (data) => {
        setSubmittedFormData(data);
    };


    const notifyUserEdited = (message) => {
        setUserEditedNotification(nanoid());
        switch (message) {
            case 'add':
                setSuccessMessage('Пользователь добавлен!');
                break;
            case 'edit':
                setSuccessMessage('Пользователь отредактирован!');
                break;
            case 'delete':
                setSuccessMessage('Пользователь удалён!');
                break;
            case 'error':
                setSuccessMessage('Произошла ошибка');
                break;
            default:
                setSuccessMessage(null);
                break;
        }
    };

    const handleSuccessOpen = () => {
        setSuccess(true);
    };

    const handleSuccessClose = () => {
        setSuccess(false);
        setSuccessMessage(null);
    };

    const resetAll = () => {
        setUserData(null);
        setSubmittedFormData(null);
    };

    const switchUserStatus = async (e) => {
        try {
            if(e.target?.value) {
                setUserData((prevState) => ({
                    ...prevState,
                    settings: {
                        ...prevState.settings,
                        status: e.target.value,
                    },
                }));
                await changeUserStatus(
                    userData._id,
                    {status: e.target.value}
                )
                .then((res) => {
                    console.log(res)
                })
                .catch((error) => {
                    console.log(error);
                })
            }
        
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <>
            <Helmet>
                <title> User Edit | Mentup Dashboard </title>
            </Helmet>
            {userData ? (
                <Container>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        open={success}
                        autoHideDuration={6000}
                        onClose={handleSuccessClose}
                    >
                        <Alert
                            variant='filled'
                            onClose={handleSuccessClose}
                            severity={
                                successMessage === 'Error occured'
                                    ? 'Ошибка'
                                    : 'Успешно'
                            }
                            sx={{ width: '100%' }}
                        >
                            {successMessage && successMessage}
                        </Alert>
                    </Snackbar>
                    <Stack
                        direction='row'
                        alignItems='center'
                        justifyContent='left'
                        mb={2}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'start',
                                columnGap: '0.5rem',
                            }}
                        >
                            <IconButton
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                <ArrowBackIosNewRoundedIcon />
                            </IconButton>
                            <Typography variant='h4'>
                                {userData && userData.username
                                    ? `Пользователь: ${userData.username}`
                                    : 'User Edit'}
                            </Typography>
                        </Box>
                    </Stack>
                    <Box 
                        sx={{ 
                            display: 'flex', 
                            flexDirection: 'row', 
                            pb: 4, 
                            px: 1,
                            ':hover': {
                                cursor: 'pointer'
                            }
                        }}
                        onClick={() => navigator.clipboard.writeText(id)}
                    >
                        <Typography sx={{ fontWeight: 'bold'}}>
                            UserID: {id}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <form
                            onSubmit={handleSubmit(submitUserData)}
                            style={{ width: '100%' }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    height: 'fit-content',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'start',
                                    alignItems: 'start',
                                    gap: '1rem',
                                }}
                            >
                                <Box sx={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'space-between',
                                }}>
                                    <Box
                                        sx={{
                                            boxSize: 'border-box',
                                            width: '50%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'start',
                                            alignItems: 'start',
                                            gap: '1rem',
                                            '@media (max-width: 900px)': {
                                                padding: '2rem',
                                                width: '100%',
                                            },
                                            '@media (max-width: 650px)': {
                                                paddingX: '0',
                                            },
                                        }}
                                    >
                                    <TextField
                                        required
                                        fullWidth
                                        variant={`outlined`}
                                        label='Имя пользователя'
                                        error={errors.username && true}
                                        helperText={errors.username?.message}
                                        {...register('username')}
                                        value={
                                            userData && userData.username
                                                ? userData.username
                                                : ''
                                        }
                                        onChange={(e) => {
                                            setUserData((prevState) => ({
                                                ...prevState,
                                                username: e.target.value,
                                            }));
                                        }}
                                    />
                                    <TextField
                                        required
                                        fullWidth
                                        variant={`outlined`}
                                        label='Email'
                                        error={errors.email && true}
                                        helperText={errors.email?.message}
                                        {...register('email')}
                                        value={userData?.email || ''}
                                        onChange={(e) => {
                                            setUserData((prevState) => ({
                                                ...prevState,
                                                email: e.target.value,
                                            }));
                                        }}
                                    />
                                    <FormControl fullWidth>
                                        <InputLabel id='add-user-input-role-label'>
                                            Role
                                        </InputLabel>
                                        <Select
                                            required
                                            labelId='add-user-input-role-label'
                                            id='add-user-role-input'
                                            label='Role'
                                            defaultValue={'buyer'}
                                            error={errors.role && true}
                                            {...register('role')}
                                            value={userData?.settings.role || ''}
                                            onChange={(e) => {
                                                setUserData((prevState) => ({
                                                    ...prevState,
                                                    settings: {
                                                        ...prevState.settings,
                                                        role: e.target.value,
                                                    },
                                                }));
                                            }}
                                        >
                                            <MenuItem value={`buyer`}>
                                                Покупатель
                                            </MenuItem>
                                            <MenuItem value={`vendor`}>
                                                Вендор
                                            </MenuItem>
                                            <MenuItem value={`admin`}>
                                                Админ
                                            </MenuItem>
                                            <MenuItem value={`support`}>
                                                Поддержка
                                            </MenuItem>
                                            <MenuItem value={`moderator`}>
                                                Модератор
                                            </MenuItem>
                                            <MenuItem value={`marketing`}>
                                                Маркетинг
                                            </MenuItem>
                                        </Select>
                                        {errors.role?.message ? (
                                            <FormHelperText
                                                sx={{
                                                    color: `${theme.palette.error.main}`,
                                                }}
                                            >
                                                {errors.role?.message}
                                            </FormHelperText>
                                        ) : null}
                                    </FormControl>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'end',
                                            alignItems: 'start',
                                        }}
                                    >
                                        <Button
                                            size={`large`}
                                            type={`submit`}
                                            variant='contained'
                                            sx={{
                                                marginTop: '1rem',
                                                '@media (max-width: 900px)': {
                                                    width: '100%',
                                                },
                                            }}
                                        >
                                            Сохранить
                                        </Button>
                                        
                                    </Box>
                                    </Box>
                                    <Box sx={{
                                        width: '45%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                        rowGap: '1.5rem',
                                        gap: '2rem',
                                        justifyContent: 'start',
                                        alignItems: 'start',
                                        marginTop: '1rem',
                                    }}>
                                        <Box>
                                            <Typography variant={`h6`}>ИП Аддресс</Typography>
                                            <Typography sx={{ fontSize: '1.1rem' }}>
                                                {userData?.settings?.ip
                                                    ? userData.settings.ip
                                                    : null}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant={`h6`}>% Площядки</Typography>
                                            <Typography sx={{ fontSize: '1.1rem' }}>
                                                {userData?.settings?.marketRate
                                                    ? userData.settings.marketRate + '%'
                                                    : null}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant={`h6`}>Статус</Typography>
                                            <Select
                                                required
                                                labelId='add-user-input-status-label'
                                                id='add-user-status-input'
                                                label='Status'
                                                defaultValue={'active'}
                                                error={errors.status && true}
                                                {...register('status')}
                                                value={
                                                    userData?.settings?.status
                                                        ? userData.settings.status
                                                        : ''
                                                }
                                                onChange={(e) => switchUserStatus(e)}
                                            >
                                                <MenuItem value={`active`}>
                                                    Активный
                                                </MenuItem>
                                                <MenuItem value={`inactive`}>
                                                    Неактивный
                                                </MenuItem>
                                                <MenuItem value={`banned`}>
                                                    Забанен
                                                </MenuItem>
                                            </Select>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </form>
                    </Box>
                </Container>
            ) : null}
        </>
    );
}

export default UserEditPage;
