import React from 'react';
import { useState } from 'react';

// mui
import {
    Box,
    Button,
    Typography,
    Modal,
    TextField,
    IconButton,
} from '@mui/material';

// form
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// icons
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

// api
import { createUser } from 'src/api/users/createUser';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    paddingX: 7,
    paddingTop: 1,
    paddingBottom: 4,
    borderRadius: '10px',
    maxHeight: '90vh',
    overflow: 'scroll',
    '@media (max-width: 650px)': {
        width: '90%',
    },
};

function AddUserModal({ notify }) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const schema = yup.object().shape({
        username: yup.string().min(3).max(40).required(),
        email: yup.string().email().required(),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const submitUserData = (data) => {
        if (data) {
            sendUserData(data);
        }
    };

    const sendUserData = async (userData) => {
        try {
            const response = await createUser({
                ...userData,
                country: 'GB',
            });
            if (response) {
                if (notify) {
                    //notify('add');
                    resetAll();
                    handleClose();
                }
            }
        } catch (error) {
            console.log(error);
            if (notify) {
                //notify('error');
                resetAll();
            }
            handleClose();
        }
    };

    const resetAll = () => {
        reset();
    };

    return (
        <Box>
            <Button
                onClick={handleOpen}
                variant='contained'
                startIcon={<AddIcon />}
            >
                Добавить пользователя
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='add-user-modal'
                aria-describedby='add-user-modal'
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'end',
                        }}
                    >
                        <IconButton
                            aria-label='close'
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            margin: '0',
                            padding: '0',
                            width: '100%',
                            marginBottom: '0.5rem',
                            textAlign: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1.8rem',
                            }}
                        >
                            Добавить нового пользователя
                        </Typography>
                    </Box>
                    <form onSubmit={handleSubmit(submitUserData)}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: '1rem',
                            }}
                        >

                            <TextField
                                required
                                fullWidth
                                variant={`outlined`}
                                label='Имя пользователя'
                                error={errors.username && true}
                                helperText={errors.username?.message}
                                {...register('username')}
                            />
                            <TextField
                                required
                                fullWidth
                                variant={`outlined`}
                                label='E-mail'
                                error={errors.email && true}
                                helperText={errors.email?.message}
                                {...register('email')}
                            />
                            <Button
                                size={`large`}
                                type={`submit`}
                                variant='contained'
                                startIcon={<AddIcon />}
                                sx={{ marginTop: '1rem' }}
                            >
                                Добавить пользователя
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </Box>
    );
}

export default AddUserModal;
