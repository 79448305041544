import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// redux
import { useSelector } from 'react-redux';

// @mui
import { styled, alpha } from '@mui/material/styles';
import {
    Box,
    Link,
    Button,
    Drawer,
    Typography,
    Avatar,
    Stack,
} from '@mui/material';

// hooks
import useResponsive from '../../../hooks/useResponsive';

// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';

// icons
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

//
import navConfig from './config';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
    openNav: PropTypes.bool,
    onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
    const { pathname } = useLocation();

    const isDesktop = useResponsive('up', 'lg');

    const user = useSelector((state) => state.user);

    useEffect(() => {
        if (openNav) {
            onCloseNav();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const renderContent = (
        <Scrollbar
            sx={{
                height: 1,
                '& .simplebar-content': {
                    height: 1,
                    display: 'flex',
                    flexDirection: 'column',
                },
            }}
        >

            <Box sx={{ px: 2.5, pt: 4, display: 'inline-flex' }}>
                <Logo />
            </Box>

            {/*
            <Box sx={{ mb: 1, mx: 2.5 }}>
                <Link underline='none'>
                    <StyledAccount>
                        {user && user.userData && user.userData.avatar ? (
                            <Avatar src={user.userData.avatar} alt='photoURL' />
                        ) : (
                            <AccountCircleIcon
                                sx={{
                                    fontSize: '2.9rem',
                                }}
                            />
                        )}

                        <Box sx={{ ml: 2 }}>
                            <Typography
                                variant='subtitle2'
                                sx={{ color: 'text.primary' }}
                            >
                                {user && user.userData && user.userData.username
                                    ? user.userData.username
                                    : null}
                            </Typography>

                            <Typography
                                variant='body2'
                                sx={{ color: 'text.secondary' }}
                            >
                                {user && user.userData && user.userData.role
                                    ? user.userData.role
                                    : null}
                            </Typography>
                        </Box>
                    </StyledAccount>
                </Link>
            </Box>
            */}

            <NavSection data={navConfig} />

            <Box sx={{ flexGrow: 1 }} />
        </Scrollbar>
    );

    return (
        <Box
            component='nav'
            sx={{
                flexShrink: { lg: 0 },
                width: { lg: NAV_WIDTH },
            }}
        >
            {isDesktop ? (
                <Drawer
                    open
                    variant='permanent'
                    PaperProps={{
                        sx: {
                            width: NAV_WIDTH,
                            bgcolor: 'background.default',
                            borderRightStyle: 'dashed',
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            ) : (
                <Drawer
                    open={openNav}
                    onClose={onCloseNav}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    PaperProps={{
                        sx: { width: NAV_WIDTH },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
        </Box>
    );
}
