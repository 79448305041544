import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

// @mui
import { alpha } from '@mui/material/styles';
import {
    Box,
    Divider,
    Typography,
    Stack,
    MenuItem,
    Avatar,
    IconButton,
    Popover,
} from '@mui/material';
// mocks_
import account from '../../../_mock/account';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from 'src/store/slices/userSlice';

// icons
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
    {
        label: 'На главную',
        icon: 'eva:home-fill',
    },
    {
        label: 'Профиль',
        icon: 'eva:person-fill',
    },
    {
        label: 'Настройки',
        icon: 'eva:settings-2-fill',
    },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
    const [open, setOpen] = useState(null);

    const dispatch = useDispatch();

    const user = useSelector((state) => state.user);

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleMenuOptionClick = (label) => {
        switch (label) {
            case 'Home':
                navigate('/dashboard/app');
                break;
            case 'Profile':
                navigate('/dashboard/profile');
                break;
            case 'Settings':
                navigate('/dashboard/settings');
                break;
            default:
                break;
        }
        handleClose();
    };

    const navigate = useNavigate();

    return (
        <>
            <IconButton
                onClick={handleOpen}
                sx={{
                    p: 0,
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: (theme) =>
                                alpha(theme.palette.grey[900], 0.8),
                        },
                    }),
                }}
            >
                {user && user.userData && user.userData.avatar ? (
                    <Avatar src={user.userData.avatar} alt='photoURL' />
                ) : (
                    <AccountCircleIcon
                        sx={{
                            fontSize: '2.9rem',
                        }}
                    />
                )}
            </IconButton>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 0,
                        mt: 1.5,
                        ml: 0.75,
                        width: 180,
                        '& .MuiMenuItem-root': {
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography variant='subtitle2' noWrap>
                        {user && user.userData && user.userData.username
                            ? user.userData.username
                            : null}
                    </Typography>
                    <Typography
                        variant='body2'
                        sx={{ color: 'text.secondary' }}
                        noWrap
                    >
                        {user && user.userData && user.userData.email
                            ? user.userData.email
                            : null}
                    </Typography>
                </Box>

                <Divider sx={{ borderStyle: 'dashed' }} />

                {/*

                <Stack sx={{ p: 1 }}>
                    {MENU_OPTIONS.map((option) => (
                        <MenuItem
                            key={option.label}
                            onClick={() => {
                                handleMenuOptionClick(option.label);
                            }}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </Stack>

                */}

                <Divider sx={{ borderStyle: 'dashed' }} />

                <MenuItem
                    onClick={() => {
                        Cookies.remove('refreshToken');
                        dispatch(setUser(null));
                        sessionStorage.removeItem('user');
                        navigate('/login');
                        handleClose();
                    }}
                    sx={{ m: 1 }}
                >
                    Выйти
                </MenuItem>
            </Popover>
        </>
    );
}
