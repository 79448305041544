import React from 'react';
import { useState } from 'react';

// mui
import {
    Box,
    Button,
    Typography,
    Modal,
    TextField,
    IconButton,
} from '@mui/material';

// form
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// icons
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { min } from 'lodash';
import { createPromocode } from 'src/api/promocodes/createPromocode';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    paddingX: 7,
    paddingTop: 1,
    paddingBottom: 4,
    borderRadius: '10px',
    maxHeight: '90vh',
    overflow: 'scroll',
    '@media (max-width: 650px)': {
        width: '90%',
    },
};

function AddPromocodeModal({ notify }) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const schema = yup.object().shape({
        name: yup
            .string('Name must be a string')
            .min(2, 'Name must be at least 2 characters')
            .max(30, 'Name must be at most 30 characters')
            .required('Name is required'),
        code: yup
            .string('Promode must be a string')
            .min(2, 'Promocode must be at least 2 characters')
            .max(30, 'Promocode must be at most 30 characters')
            .required('Promocode is required')
            .test(
                'Valid Promocode Value',
                'Promocode Value must contain letters and numbers only',
                (value) => value.match(/^[a-zA-Z0-9]+$/)
            ),
        discount: yup
            .number('Discount must be a number')
            .min(1, 'Discount must be at least 1%')
            .max(100, 'Discount must be at most 100%')
            .required('Discount is required')
            .test(
                'Valid discount value',
                'Discount value must be between 1 and 100 and have 2 decimal places at most',
                (value) => {
                    if (
                        value.toString().match(/^\d+\.\d{1,2}$/) ||
                        value.toString().match(/^\d+$/)
                    ) {
                        return true;
                    }
                    return false;
                }
            ),
        promoExpiryDate: yup
            .date('Promocode Expiry Date must be have a date format')
            .required('Promocode Expiry Date is required')
            .min(new Date(), 'Promocode Expiry Date must be in the future'),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const sendPromocodeData = async (data) => {
        try {
            const res = await createPromocode(data);
            if (res) {
                if (notify) {
                    //notify('add');
                }
                reset();
                handleClose();
            }
        } catch (err) {
            console.log(err);
            //notify('error');
            handleClose();
        }
    };

    const submitPromocodeData = (data) => {
        let isoDate = null;
        try {
            isoDate = data.promoExpiryDate.toISOString();
            if (isoDate) {
                sendPromocodeData({ ...data, expiryDate: isoDate });
            }
        } catch (err) {}
    };

    return (
        <Box>
            <Button
                onClick={handleOpen}
                variant='contained'
                startIcon={<AddIcon />}
            >
                Новый промокод
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='add-promocode-modal'
                aria-describedby='add-promocode-modal'
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'end',
                        }}
                    >
                        <IconButton
                            aria-label='close'
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            margin: '0',
                            padding: '0',
                            width: '100%',
                            marginBottom: '0.5rem',
                            textAlign: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1.8rem',
                            }}
                        >
                            Добавить новый промокод
                        </Typography>
                    </Box>
                    <form onSubmit={handleSubmit(submitPromocodeData)}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: '1rem',
                                padding: '1rem 3rem',
                                '@media (max-width: 650px)': {
                                    paddingX: 0,
                                },
                            }}
                        >

                            <TextField
                                required
                                fullWidth
                                variant={`outlined`}
                                label='название промокода'
                                error={errors.name && true}
                                helperText={errors.name?.message}
                                {...register('name')}
                            />

                            <TextField
                                required
                                fullWidth
                                variant={`outlined`}
                                label='Код'
                                error={errors.code && true}
                                helperText={errors.code?.message}
                                {...register('code')}
                            />

                            <TextField
                                required
                                fullWidth
                                // type='number'
                                variant={`outlined`}
                                label='Скидка промокода %'
                                error={errors.discount && true}
                                helperText={errors.discount?.message}
                                {...register('discount')}
                            />

                            <TextField
                                required
                                type='date'
                                fullWidth
                                // type='number'
                                variant={`outlined`}
                                label='Срок действия промокода'
                                InputLabelProps={{ shrink: true }}
                                error={errors.promoExpiryDate && true}
                                helperText={errors.promoExpiryDate?.message}
                                InputProps={{
                                    inputProps: { max: '9999-12-31' },
                                }}
                                {...register('promoExpiryDate')}
                                onChange={(e) => {
                                    console.log(e.target.value);
                                }}
                            />

                            <Button
                                size={`large`}
                                type={`submit`}
                                variant='contained'
                                startIcon={<AddIcon />}
                                sx={{ marginTop: '1rem' }}
                            >
                                Добавить промокод
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </Box>
    );
}

export default AddPromocodeModal;
