import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const headCells = [
    {
        id: 'productName',
        numeric: false,
        disablePadding: true,
        label: 'Наименование',
    },
    {
        id: 'price',
        numeric: true,
        disablePadding: false,
        label: 'Цена',
    },
    {
        id: 'total',
        numeric: true,
        disablePadding: false,
        label: 'Итого',
    },
];

function OrderProductsTableHead(props) {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props;

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'center'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

OrderProductsTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function OrderProductsTableToolbar(props) {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(
                            theme.palette.primary.main,
                            theme.palette.action.activatedOpacity
                        ),
                }),
            }}
        >
            <Typography
                sx={{ flex: '1 1 100%' }}
                variant='h6'
                id='tableTitle'
                component='div'
            >
                Товары
            </Typography>

        </Toolbar>
    );
}

OrderProductsTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default function OrderProductsTable({ orderData }) {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    let emptyRows = 0;
    if (orderData?.products?.length > 0) {
        emptyRows =
            page > 0
                ? Math.max(
                      0,
                      (1 + page) * rowsPerPage - orderData.products.length
                  )
                : 0;
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table
                        sx={{
                            width: '100%',
                            maxWidth: '100%',
                        }}
                        aria-labelledby='tableTitle'
                    >
                        <OrderProductsTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={orderData?.products?.length || 0}
                        />
                        <TableBody>
                            {orderData?.products?.map((product, index) => {
                                      const labelId = `order-products-table-checkbox-${index}`;

                                      return (
                                          <TableRow
                                              key={index}
                                              hover
                                              tabIndex={-1}
                                          >
                                              <TableCell
                                                  component='th'
                                                  id={labelId}
                                                  scope='row'
                                              >
                                                  {product?.name || ''}
                                              </TableCell>
                                              <TableCell align='center'>
                                                  {`${product?.price} ₽` || ''}
                                              </TableCell>
                                              <TableCell align='center'>
                                                  {`${orderData?.transaction?.total} ₽`}
                                              </TableCell>
                                          </TableRow>
                                      );
                                  })}
                            {emptyRows > 0 && (
                                <TableRow

                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    labelRowsPerPage="Выводить по:"
                    rowsPerPageOptions={[5, 10, 25]}
                    component='div'
                    count={orderData?.products?.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}
