import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material';

function TableStatusCell({ contents }) {
    const theme = useTheme();

    let backgroundColor = `${theme.palette.warning.main}`;

    switch (contents) {
        case 'pending':
            backgroundColor = `${theme.palette.warning.main}`;
            break;
        case 'approved':
        case 'succeeded':
        case 'active':
            backgroundColor = `#5cb85c`;
            break;
        case 'banned':
        case 'declined':
        case 'refunded':
            backgroundColor = `${theme.palette.error.main}`;
            break;
        case 'Продажа':
        case 'order':
            backgroundColor = `#9B59B6`;
            break;
        case 'Покупка':
                backgroundColor = `#5cb85c`;
                break;
        case 'purchase':
            backgroundColor = `#b3afee`;
            break;
        case 'deposit':
            backgroundColor = `#afcfee`;
            break;  
        default:
            backgroundColor = `${theme.palette.action.disabledBackground}`;
    }

    return (
        <Box
            sx={{
                borderRadius: '5px',
                boxSizing: 'border-box',
                paddingY: '0.5rem',
                paddingX: '1rem',
                textAlign: 'center',
                width: 'fit-content',
                backgroundColor,
            }}
        >
            <Typography sx={{ 
                fontWeight: 'bold',
                fontSize: '11px',
                textTransform: 'uppercase',
                color: 'white'
            }}>
                {contents ? contents : ''}
            </Typography>
        </Box>
    );
}

export default TableStatusCell;
