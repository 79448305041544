import axios from 'axios';
import Cookies from 'js-cookie';

const API = axios.create({
    baseURL: process.env.NODE_ENV.includes('production')
        ? process.env.REACT_APP_SERVER_URL
            ? process.env.REACT_APP_SERVER_URL
            : ''
        : 'http://localhost:3030/api',
});

export const getWallets = (limit, page, sortBy, order, search) => {
    const token = Cookies.get('refreshToken');
    if (token && limit && (page || page === 0) && sortBy && order) {
        let backendPage = page + 1;
        if(search){
            return API.get(
                `/wallet/wallets?limit=${limit}&page=${backendPage}&${sortBy}=${order}&search=${search}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
        } else {
            return API.get(
                `/wallet/wallets?limit=${limit}&page=${backendPage}&${sortBy}=${order}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
        }
        
    }
};
