import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material';

// mui
import {
    Container,
    Stack,
    Typography,
    Box,
    TextField,
    MenuItem,
    FormHelperText,
    Button,
    Select,
    FormControl,
    InputLabel,
    InputAdornment,
    IconButton,
} from '@mui/material';

// components
import OrderProductsTable from 'src/components/orderProductsTable';

// form
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { getOrder } from 'src/api/orders/getOrder';
import { isoToReadableDate } from 'src/utils/isoToReadableDate';

function OrderEditPage() {
    console.log('ORDER PAGE');

    const { id } = useParams();

    const theme = useTheme();

    const navigate = useNavigate();

    const [orderData, setOrderData] = useState(null);

    useEffect(() => {
        resetAll();
        const getOrderData = async (id) => {
            try {
                const data = await getOrder(id);
                if (data?.data) {
                    setOrderData(data.data);
                }
            } catch (error) {
                navigate('/dashboard/orders');
            }
        };
        if (id) {
            getOrderData(id);
        }
    }, [id]);

    const resetAll = () => {
        setOrderData(null);
    };


    return (
        <>
            <Helmet>
                <title> Order | Mentup Dashboard </title>
            </Helmet>
            {orderData ? (
                <Container>
                    <Stack
                        direction='row'
                        alignItems='center'
                        justifyContent='left'
                        mb={2}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'start',
                                columnGap: '0.5rem',
                            }}
                        >
                            <IconButton
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                <ArrowBackIosNewRoundedIcon />
                            </IconButton>
                            <Typography variant='h4'>
                                Заказ:
                                <span style={{ fontWeight: 'normal' }}>
                                    {orderData?._id || ''}
                                </span>
                            </Typography>
                        </Box>
                    </Stack>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                boxSizing: 'border-box',
                                padding: '1rem 1rem',
                                height: 'fit-content',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                gap: '1rem',
                                alignItems: 'start',
                                borderRadius: '12px',
                                backgroundColor: (theme) =>
                                    theme.palette.background.paper,
                                '@media (max-width: 900px)': {
                                    flexDirection: 'column',
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    boxSize: 'border-box',
                                    width: '48%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'start',
                                    alignItems: 'start',
                                    gap: '1rem',
                                    '@media (max-width: 900px)': {
                                        padding: '2rem',
                                        width: '100%',
                                    },
                                    '@media (max-width: 650px)': {
                                        paddingX: '0',
                                    },
                                }}
                            >
                                <Box sx={{ width: '100%' }}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            height: '64px',
                                            paddingLeft: '16px',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '1.125rem',
                                                fontWeight: 'bold',
                                            }}
                                        >{`Детали`}</Typography>
                                    </Box>
                                    <TextField
                                        fullWidth
                                        variant={`outlined`}
                                        label='Никнейм покупателя'
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        value={orderData?.user?.username}
                                    />
                                    <Button
                                        type='text'
                                        onClick={() => {
                                            if (orderData?.user?._id) {
                                                navigate(
                                                    `/dashboard/user/${orderData.user._id}`
                                                );
                                            }
                                        }}
                                    >
                                        На страницу продавца
                                    </Button>
                                </Box>
                                <TextField
                                    fullWidth
                                    variant={`outlined`}
                                    label='Статус'
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={orderData?.status}
                                />
                                <TextField
                                    fullWidth
                                    variant={`outlined`}
                                    label='ID транзакции'
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={orderData?.transaction?._id || ''}
                                />

                                <TextField
                                    fullWidth
                                    type={'date'}
                                    variant={`outlined`}
                                    label='Дата'
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={orderData?.createdAt?.slice(0, 10) || ''}
                                />

                                <TextField
                                    fullWidth
                                    variant={`outlined`}
                                    label='Итого'
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        readOnly: true,
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <Typography>₽</Typography>
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={orderData?.transaction?.total || 0}
                                />

                            </Box>
                            {/* </form> */}
                            <Box
                                sx={{
                                    width: '48%',
                                    height: '100%',
                                    '@media (max-width: 900px)': {
                                        paddingX: '2rem',
                                        width: '100%',
                                    },
                                }}
                            >
                                <OrderProductsTable orderData={orderData} />
                            </Box>
                        </Box>

                    </Box>
                </Container>
            ) : null}
        </>
    );
}

export default OrderEditPage;
