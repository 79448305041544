import { Helmet } from 'react-helmet-async';
// import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Popover,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Snackbar,
    Alert,
    Box,
} from '@mui/material';

// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import AddProductModal from 'src/components/add-product-modal/AddProductModal';
import ProductCodesModal from 'src/components/product-codes-modal/ProductCodesModal';
import DisableEnableModal from 'src/components/product-disable-enable-modal/DisableEnableModal';

// sections
import {
    ProductListHead,
} from 'src/sections/@dashboard/products';

// utils
import { isoToReadableDate } from 'src/utils/isoToReadableDate';
import { nanoid } from 'nanoid';

// api
import { getProducts } from 'src/api/products/getProducts';
import { CategoryListToolbar } from 'src/sections/@dashboard/categories';

// ----------------------------------------------------------------------

// IDS SHOULD BE EQUAL TO PROPERTIES IN THE OBJECTS
const TABLE_HEAD = [
    { id: 'user', label: 'Пользователь', align: 'left' },
    { id: 'name', label: 'Наименование продукта', align: 'left' },
    { id: 'price', label: 'Цена', align: 'left' },
    { id: 'quantity', label: 'Количество', align: 'left' },
    { id: 'updatedAt', label: 'Обновление', align: 'left' },
    { id: '' },
];

// ----------------------------------------------------------------------

export default function ProductsPage() {

    const [open, setOpen] = useState(null);

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('desc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('updatedAt');

    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [products, setProducts] = useState(null);

    const [productsTotal, setProductsTotal] = useState(0);

    const [success, setSuccess] = useState(false);

    const [successMessage, setSuccessMessage] = useState(null);

    const [productAddedNotification, setProductAddedNotification] =
        useState(null);

    const [productToEdit, setProductToEdit] = useState(null);

    useEffect(() => {
        setProducts(null);
        setSelected([]);
        const getProductsData = async () => {
            try {
                const data = await getProducts(
                    rowsPerPage,
                    page,
                    orderBy,
                    order
                );
                if (data?.data?.products) {
                    setProducts(data.data.products);
                }
                if (data?.data?.totalProducts) {
                    setProductsTotal(data.data.totalProducts);
                }
            } catch (error) {
                setProducts(null);
                setSelected([]);
            }
        };
        getProductsData();
    }, [rowsPerPage, page, orderBy, order, productAddedNotification]);

    const navigate = useNavigate();

    // Display success message when success message is set
    useEffect(() => {
        if (successMessage) {
            handleSuccessOpen();
        }
    }, [successMessage]);

    const handleOpenMenu = (event, product) => {
        if (product) {
            setProductToEdit(product);
            setOpen(event.currentTarget);
        }
    };

    const handleCloseMenu = () => {
        setProductToEdit(null);
        setOpen(null);
    };

    const handleRequestSort = (event, property) => {
        setProducts(null);
        setSelected([]);
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const handleChangePage = (event, newPage) => {
        setProducts(null);
        setSelected([]);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setProducts(null);
        setSelected([]);
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const notifyProductAdded = (message) => {
        if (message === 'add' || message === 'delete' || message === 'edit' || message === 'enabled' || message === 'disabled' || message === 'error') {
            setPage(0);
            setProductAddedNotification(nanoid());
        }
        handleCloseMenu();
        switch (message) {
            case 'add':
                setSuccessMessage('Продукт добавлен!');
                break;
            case 'edit':
                setSuccessMessage('Продукт отредактирован!');
                break;
            case 'delete':
                setSuccessMessage('Продукт удалён!');
                break;
            case 'error':
                setSuccessMessage('Произошла ошибка');
                break;
            case 'enabled':
                setSuccessMessage('Продукт включен!');
                break;
            case 'disabled':
                setSuccessMessage('Продукт выключен!');
                break;
            case 'error':
                setSuccessMessage('Произошла ошибка');
            default:
                setSuccessMessage(null);
                break;
        }
    };

    const handleSuccessOpen = () => {
        setSuccess(true);
    };

    const handleSuccessClose = () => {
        setSuccess(false);
        setSuccessMessage(null);
    };

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - productsTotal) : 0;

    const isNotFound = false;

    const handleSearch = async (searchValue) => {
        setProducts(null);
        setPage(0);
        try {
            let data = null;
            if(searchValue.length >= 3) {
                data = await getProducts(rowsPerPage, page, orderBy, order, searchValue)
            } else if(searchValue.length === 0) {
                data = await getProducts(rowsPerPage, page, orderBy, order)
            }
            if (data?.data?.products) {
                setProducts(data.data.products);
            }
            if (data?.data?.totalProducts) {
                setProductsTotal(data.data.totalProducts);
            }
            
        } catch (error) {
            setProducts(null);
        }
    }

    return (
        <>
            <Helmet>
                <title> Products | Mentup Dashboard </title>
            </Helmet>

            <Container>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={success}
                    autoHideDuration={6000}
                    onClose={handleSuccessClose}
                >
                    <Alert
                        variant='filled'
                        onClose={handleSuccessClose}
                        severity={
                            successMessage === 'Error occured'
                                ? 'error'
                                : 'success'
                        }
                        sx={{ width: '100%' }}
                    >
                        {successMessage && successMessage}
                    </Alert>
                </Snackbar>

                <Card>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mr: 3,
                    }}>
                        <CategoryListToolbar
                            handleSearchGlobal={handleSearch}
                            title={'товара'}
                        />
                    </Box>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <ProductListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={
                                        products && products.length
                                            ? products.length
                                            : 0
                                    }
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {products &&
                                        products.map((product, index) => {
                                            const selectedProduct =
                                                selected.indexOf(
                                                    product._id
                                                ) !== -1;

                                            return (
                                                <TableRow
                                                    hover
                                                    key={index}
                                                    tabIndex={-1}
                                                    role='checkbox'
                                                    selected={selectedProduct}
                                                >

                                                    <TableCell align="left">
                                                        <Typography
                                                            sx={{
                                                                ':hover': {
                                                                    cursor: 'pointer',
                                                                    textDecoration: 'underline'
                                                                }
                                                            }}
                                                            onClick={() => window.open('/dashboard/user/' + product.user._id, '_blank')}
                                                        >
                                                            {product.user?.username || ''}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell align='left'>
                                                    <Typography
                                                        component="a"
                                                        href={`https://mentup-store.com/profile/${product.user.userSlug}/${product.slug}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        sx={{
                                                            ':hover': {
                                                                cursor: 'pointer',
                                                                textDecoration: 'underline'
                                                            },
                                                            color: 'inherit',
                                                            textDecoration: 'none'
                                                        }}
                                                    >
                                                        {product.name || ''}
                                                    </Typography>
                                                    </TableCell>

                                                    <TableCell align='left'>
                                                        <Typography>
                                                            {`${`${product.price - (product.price * (product.promocode ? product.promocode.discount / 100 : 1)).toFixed(2)}`.replace(/\d(?=(\d{3})+\.)/g, "$&,")} ₽`}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell align='left'>
                                                        <Typography>
                                                            {product.quantity}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell align='left'>
                                                        {isoToReadableDate(
                                                            product.updatedAt
                                                        )}
                                                    </TableCell>

                                                    <TableCell align='right'>
                                                        <IconButton
                                                            size='large'
                                                            color='inherit'
                                                            onClick={(e) => {
                                                                if (product) {
                                                                    handleOpenMenu(
                                                                        e,
                                                                        product
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <Iconify
                                                                icon={
                                                                    'eva:more-vertical-fill'
                                                                }
                                                            />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{ height: 53 * emptyRows }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell
                                                align='center'
                                                colSpan={6}
                                                sx={{ py: 3 }}
                                            >
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography
                                                        variant='h6'
                                                        paragraph
                                                    >
                                                        Не найдено
                                                    </Typography>

                                                    <Typography variant='body2'>
                                                        Не найдено
                                                        &nbsp;
                                                        <strong>
                                                            &quot;
                                                            {'Search Value'}
                                                            &quot;
                                                        </strong>
                                                        .
                                                        <br /> Попробуйте проверить опечатки или корректируйте запрос.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        labelRowsPerPage="Выводить по:"
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={productsTotal && productsTotal}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <DisableEnableModal 
                    product={productToEdit}
                    notify={notifyProductAdded}
                />

            </Popover>
        </>
    );
}
