
import { useState, useEffect } from 'react';

// mui
import {
    Box,
    Button,
    Typography,
    Modal,
    TextField,
    IconButton,
} from '@mui/material';

// form
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// icons
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

// API
import { createTransaction } from 'src/api/transactions/createTransaction';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    paddingX: 7,
    paddingTop: 1,
    paddingBottom: 4,
    borderRadius: '10px',
    maxHeight: '90vh',
    overflow: 'scroll',
    '@media (max-width: 650px)': {
        paddingX: 4,
        width: '90%',
    },
};

function AddTransactionModal({ notify }) {
    
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [submittedFormData, setSubmittedFormData] = useState(null);

    const schema = yup.object().shape({
        userId: yup.string().max(40).required(),
        amount: yup.number().round().required(),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });


    const submitSoftwareData = (data) => {
        setSubmittedFormData(data)
    };

    useEffect(() => {
        if (submittedFormData) {
            const sendAddTransactionData = async () => {
                try {
                    const data = await createTransaction({
                        ...submittedFormData,
                    });
                    if (data && data.data) {
                        setSubmittedFormData(null);
                        reset();
                        if (notify) {
                            notify('add');
                        }
                        handleClose();
                    }
                } catch (error) {
                    console.log(error);
                }
            };
            sendAddTransactionData();
        }
    }, [submittedFormData]);


    return (
        <Box>
            <Button
                onClick={handleOpen}
                variant='contained'
                startIcon={<AddIcon />}
            >
                New Transaction
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='add-software-modal'
                aria-describedby='add-software-modal'
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'end',
                        }}
                    >
                        <IconButton
                            aria-label='close'
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            margin: '0',
                            padding: '0',
                            width: '100%',
                            marginBottom: '0.5rem',
                            textAlign: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1.8rem',
                            }}
                        >
                            Add new Transaction
                        </Typography>
                    </Box>
                    <form onSubmit={handleSubmit(submitSoftwareData)}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: '1rem',
                            }}
                        >
                            <TextField
                                required
                                fullWidth
                                variant={`outlined`}
                                label='User ID'
                                error={errors.name && true}
                                helperText={errors.name?.message}
                                {...register('userId')}
                            />

                            <TextField
                                required
                                fullWidth
                                variant={`outlined`}
                                label='Amount'
                                error={errors.name && true}
                                helperText={errors.name?.message}
                                {...register('amount')}
                            />

                            <Button
                                size={`large`}
                                type={`submit`}
                                variant='contained'
                                startIcon={<AddIcon />}
                                sx={{ marginTop: '1rem' }}
                            >
                                Add Transaction
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </Box>
    );
}

export default AddTransactionModal;
