export const isoToReadableDate = (isoDate) => {
    if (isoDate) {
        // Split the ISO date string into date and time parts
        const [datePart, timePart] = isoDate.split('T');

        // Extract year, month, and day
        const [year, month, day] = datePart.split('-');
        
        // Convert the month number to its full name
        const monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
        ];
        const monthName = monthNames[parseInt(month, 10) - 1];
        
        // Extract hours, minutes, and seconds from the time part
        const [hours, minutes, seconds] = timePart.slice(0, 8).split(':');

        // Format the date and time
        return `${day} ${monthName} ${year} ${hours}:${minutes}:${seconds}`;
    } else {
        return null;
    }
};
