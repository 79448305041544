import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// @mui
import {
    Card,
    Table,
    Paper,
    Popover,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
} from '@mui/material';

// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';

// sections
import { OrderListHead } from '../sections/@dashboard/orders';

// icons
import ReadMoreIcon from '@mui/icons-material/ReadMore';

// utils
import { isoToReadableDate } from 'src/utils/isoToReadableDate';

// api
import { getOrders } from 'src/api/orders/getOrders';
import TableStatusCell from 'src/components/table-status-cell';

// ----------------------------------------------------------------------

// IDS SHOULD BE EQUAL TO PROPERTIES IN THE OBJECTS
const TABLE_HEAD = [
    { id: '_id', label: '#', align: 'left' },
    { id: 'buyerUsername', label: 'Пользователь', align: 'left' },
    { id: 'price', label: 'Всего', align: 'left' },
    { id: '2', label: 'Тип', align: 'left' },
    { id: 'status', label: 'Статус', align: 'left' },
    { id: 'lastUpdated', label: 'Обновлено', align: 'left' },
    { id: 'createdAt', label: 'Дата', align: 'left' },
    { id: '' },
];

// ----------------------------------------------------------------------

export default function OrdersPage() {

    const [open, setOpen] = useState(null);

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('desc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('createdAt');

    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [orders, setOrders] = useState(null);

    const [ordersTotal, setOrdersTotal] = useState(0);

    const [orderToView, setOrderToView] = useState(null);

    useEffect(() => {
        setOrders(null);
        setSelected([]);
        const getOrdersData = async () => {
            try {

                const data = await getOrders(rowsPerPage, page, orderBy, order, );
                if (data?.data?.orders) {
                    setOrders(data.data.orders);
                }
                if (data?.data?.totalOrders) {
                    setOrdersTotal(data.data.totalOrders);
                }
            } catch (error) {
                setOrders(null);
                setSelected([]);
            }
        };
        getOrdersData();
    }, [rowsPerPage, page, orderBy, order]);


    const navigate = useNavigate();

    const handleOpenMenu = (event, id) => {
        if (id) {
            setOrderToView(id);
            setOpen(event.currentTarget);
        }
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleRequestSort = (event, property) => {
        setOrders(null);
        setSelected([]);
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setOrders(null);
        setSelected([]);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setOrders(null);
        setSelected([]);
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - ordersTotal) : 0;

    const isNotFound = false;

    return (
        <>
            <Helmet>
                <title> Orders | Mentup Dashboard </title>
            </Helmet>

            <Container>
                <Card>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <OrderListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={
                                        orders && orders.length
                                            ? orders.length
                                            : 0
                                    }
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {orders &&
                                        orders.map((order, index) => {
                                            const selectedOrder = selected.indexOf(order._id) !== -1;

                                            return (
                                                <TableRow
                                                    hover
                                                    key={index}
                                                    tabIndex={-1}
                                                    role='checkbox'
                                                    selected={selectedOrder}
                                                >

                                                    <TableCell align="left">
                                                        <Typography
                                                            sx={{
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                                maxWidth: '50px',
                                                                ':hover': {
                                                                    cursor: 'pointer',
                                                                    textDecoration: 'underline'
                                                                }
                                                            }}
                                                            title={order._id || ''}
                                                            onClick={() => navigator.clipboard.writeText(order._id || '')}
                                                        >
                                                            {order._id || ''}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align='left'>
                                                        <Typography
                                                            sx={{
                                                                ':hover': {
                                                                    cursor: 'pointer',
                                                                    textDecoration: 'underline',
                                                                },
                                                            }}
                                                            onClick={() => {
                                                                const targetUser = order.user?.[0] || order.seller?.[0];
                                                                if (targetUser?._id) {
                                                                    window.open(`/dashboard/user/${targetUser._id}`, '_blank');
                                                                }
                                                            }}
                                                        >
                                                            {order.user?.[0]?.username || order.seller?.[0]?.username || ''}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell align='left'>
                                                        <Typography>
                                                            {order.transaction[0]?.total + ' ₽' || ''}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell align='left'>
                                                        <TableStatusCell
                                                            contents={order.seller?.[0] ? 'Продажа' : 'Покупка'}
                                                        />
                                                    </TableCell>

                                                    <TableCell align='left'>
                                                        <TableStatusCell
                                                            contents={order.status || ''}
                                                        />
                                                    </TableCell>


                                                    <TableCell align='left'>
                                                        {order.updatedAt && (
                                                            <Typography>
                                                                {isoToReadableDate(
                                                                    order.updatedAt
                                                                )}
                                                            </Typography>
                                                        )}
                                                    </TableCell>

                                                    <TableCell align='left'>
                                                        <Typography>
                                                            {isoToReadableDate(
                                                                order.createdAt
                                                            )}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell align='right'>
                                                        <IconButton
                                                            size='large'
                                                            color='inherit'
                                                            onClick={(e) => {
                                                                if (order._id) {
                                                                    handleOpenMenu(
                                                                        e,
                                                                        order._id
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <Iconify
                                                                icon={
                                                                    'eva:more-vertical-fill'
                                                                }
                                                            />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{ height: 53 * emptyRows }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell
                                                align='center'
                                                colSpan={6}
                                                sx={{ py: 3 }}
                                            >
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography
                                                        variant='h6'
                                                        paragraph
                                                    >
                                                        Не найдено
                                                    </Typography>

                                                    <Typography variant='body2'>
                                                        Не найдено
                                                        &nbsp;
                                                        <strong>
                                                            &quot;
                                                            {'Search Value'}
                                                            &quot;
                                                        </strong>
                                                        .
                                                        <br /> Попробуйте проверить опечатки или корректируйте запрос.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        labelRowsPerPage="Выводить по:"
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={ordersTotal && ordersTotal}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem
                    onClick={() => {
                        if (orderToView) {
                            navigate(`/dashboard/order/${orderToView}`);
                        }
                    }}
                >
                    <ReadMoreIcon sx={{ marginRight: '1rem' }} />
                    Подробнее
                </MenuItem>
            </Popover>
        </>
    );
}
