import React, { useEffect } from 'react';
import { useState } from 'react';

// mui
import {
    Box,
    Button,
    Typography,
    Modal,
    TextField,
    IconButton,
    MenuItem,
} from '@mui/material';

// form
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// icons
import CloseIcon from '@mui/icons-material/Close';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { isoToReadableDate } from 'src/utils/isoToReadableDate';

// api
import { updatePromocode } from 'src/api/promocodes/updatePromocode';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    paddingX: 7,
    paddingTop: 1,
    paddingBottom: 4,
    borderRadius: '10px',
    maxHeight: '90vh',
    overflow: 'scroll',
    '@media (max-width: 650px)': {
        width: '90%',
    },
};

function EditPromocodeModal({ notify, promocode }) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [promoData, setPromoData] = useState(null);

    useEffect(() => {
        setPromoData(promocode);
    }, [promocode]);

    const schema = yup.object().shape({
        name: yup
            .string('Name must be a string')
            .min(5, 'Name must be at least 5 characters')
            .max(30, 'Name must be at most 30 characters')
            .required('Name is required'),
        code: yup
            .string('Promode must be a string')
            .min(5, 'Promocode must be at least 5 characters')
            .max(30, 'Promocode must be at most 30 characters')
            .required('Promocode is required')
            .test(
                'Valid Promocode Value',
                'Promocode Value must contain letters and numbers only',
                (value) => value.match(/^[a-zA-Z0-9]+$/)
            ),
        discount: yup
            .number('Discount must be a number')
            .min(1, 'Discount must be at least 1%')
            .max(100, 'Discount must be at most 100%')
            .required('Discount is required')
            .test(
                'Valid discount value',
                'Discount value must be between 1 and 100 and have 2 decimal places at most',
                (value) => {
                    if (
                        value.toString().match(/^\d+\.\d{1,2}$/) ||
                        value.toString().match(/^\d+$/)
                    ) {
                        return true;
                    }
                    return false;
                }
            ),
        expiryDate: yup
            .date('Promocode Expiry Date must be have a date format')
            .required('Promocode Expiry Date is required')
            .min(new Date(), 'Promocode Expiry Date must be in the future'),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const sendPromocodeData = async (data, id) => {
        try {
            const res = await updatePromocode(data, id);
            if (res) {
                if (notify) {
                   // notify('edit');
                }
                handleClose();
            }
        } catch (err) {
            console.log(err);
            //notify('error');
            handleClose();
        }
    };

    const submitPromocodeData = (data) => {
        // console.log(data);
        let isoDate = null;
        try {
            isoDate = data.expiryDate.toISOString();
            if (isoDate) {
                if (promocode?._id) {
                    sendPromocodeData(
                        { ...data, expiryDate: isoDate },
                        promocode._id
                    );
                }
            }
        } catch (err) {}
    };

    return (
        <Box>
            <MenuItem onClick={handleOpen}>
                <EditRoundedIcon sx={{ mr: 2 }} /> Редактировать
            </MenuItem>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='edit-promocode-modal'
                aria-describedby='edit-promocode-modal'
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'end',
                        }}
                    >
                        <IconButton
                            aria-label='close'
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            margin: '0',
                            padding: '0',
                            width: '100%',
                            marginBottom: '0.5rem',
                            textAlign: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1.8rem',
                            }}
                        >
                            {promoData && promoData.name
                                ? `Редактировать промокод: ${promoData.name}`
                                : `Редактировать промокод`}
                        </Typography>
                    </Box>
                    {promoData ? (
                        <form onSubmit={handleSubmit(submitPromocodeData)}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    rowGap: '1rem',
                                    padding: '1rem 3rem',
                                    '@media (max-width: 650px)': {
                                        paddingX: 0,
                                    },
                                }}
                            >
                                <TextField
                                    fullWidth
                                    variant={`outlined`}
                                    label='Owner ID'
                                    value={
                                        promoData && promoData.user
                                            ? promoData.user
                                            : ''
                                    }
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <TextField
                                    required
                                    fullWidth
                                    variant={`outlined`}
                                    label='Promocode Name'
                                    error={errors.name && true}
                                    helperText={errors.name?.message}
                                    {...register('name')}
                                    value={
                                        promoData && promoData.name
                                            ? promoData.name
                                            : ''
                                    }
                                    onChange={(e) => {
                                        setPromoData((prevState) => {
                                            return {
                                                ...prevState,
                                                name: e.target.value,
                                            };
                                        });
                                    }}
                                />

                                <TextField
                                    required
                                    fullWidth
                                    variant={`outlined`}
                                    label='Promocode Code'
                                    error={errors.code && true}
                                    helperText={errors.code?.message}
                                    {...register('code')}
                                    value={
                                        promoData && promoData.code
                                            ? promoData.code
                                            : ''
                                    }
                                    onChange={(e) => {
                                        setPromoData((prevState) => {
                                            return {
                                                ...prevState,
                                                code: e.target.value,
                                            };
                                        });
                                    }}
                                />

                                <TextField
                                    required
                                    fullWidth
                                    variant={`outlined`}
                                    label='Promocode Discount %'
                                    error={errors.discount && true}
                                    helperText={errors.discount?.message}
                                    {...register('discount')}
                                    value={
                                        promoData && promoData.discount
                                            ? promoData.discount
                                            : ''
                                    }
                                    onChange={(e) => {
                                        setPromoData((prevState) => {
                                            return {
                                                ...prevState,
                                                discount: e.target.value,
                                            };
                                        });
                                    }}
                                />

                                <TextField
                                    required
                                    type='date'
                                    fullWidth
                                    variant={`outlined`}
                                    label='Promocode Expiry Date'
                                    InputLabelProps={{ shrink: true }}
                                    error={errors.expiryDate && true}
                                    helperText={errors.expiryDate?.message}
                                    InputProps={{
                                        inputProps: { max: '9999-12-31' },
                                    }}
                                    {...register('expiryDate')}
                                    value={
                                        promoData &&
                                        promoData.expiryDate &&
                                        promoData.expiryDate.length >= 10
                                            ? promoData.expiryDate.slice(0, 10)
                                            : ''
                                    }
                                    onChange={(e) => {
                                        setPromoData((prevState) => {
                                            return {
                                                ...prevState,
                                                expiryDate: e.target.value,
                                            };
                                        });
                                    }}
                                />

                                <Button
                                    size={`large`}
                                    type={`submit`}
                                    variant='contained'
                                    sx={{ marginTop: '1rem' }}
                                >
                                    Submit
                                </Button>
                            </Box>
                        </form>
                    ) : null}
                </Box>
            </Modal>
        </Box>
    );
}

export default EditPromocodeModal;
