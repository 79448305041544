import axios from 'axios';
import Cookies from 'js-cookie';

const API = axios.create({
    baseURL: process.env.NODE_ENV.includes('production')
        ? process.env.REACT_APP_SERVER_URL
            ? process.env.REACT_APP_SERVER_URL
            : ''
        : 'http://localhost:3030/api',
});

export const getProducts = (limit, page, sortBy, order, searchValue) => {
    const token = Cookies.get('refreshToken');
    if (token && limit && (page || page === 0) && sortBy && order) {
        let backendPage = page + 1;
        if(searchValue){
            return API.get(
                `/item/adm/all?limit=${limit}&page=${backendPage}&${sortBy}=${order}&search=${searchValue}`,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
        } else {
            return API.get(
                `/item/adm/all?limit=${limit}&page=${backendPage}&${sortBy}=${order}`,
                {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token}`,
                    },
                }
            );
        }
    }
};
