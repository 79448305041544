/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback } from "react";

export * from "./alerts";

export function assetsPath(path) {
  const prefix =
    (process.env.BASE_PATH ?? "") + (path.at(0) === "/" ? "" : "/");
  return `${prefix}${path}`;
}

export const useServiceWorker = () => {
  if (typeof window !== "object") {
    return { getServiceWorker: () => Promise.resolve(null) };
  }

  const getWorker = useCallback(async (worker) => {
    const path = assetsPath(worker);
  
    // Fetch service worker registration
    const registration = await navigator.serviceWorker.getRegistration(path);
    if (registration) {
      return registration;
    }
  
    // Register a new service worker
    try {
      const newRegistration = await navigator.serviceWorker.register(path);
      return newRegistration;
    } catch (error) {
      console.error(`Failed to register service worker at ${path}`, error);
      throw error;
    }
  }, []);

  const getServiceWorker = useCallback(
    async (worker) => {
      const registration = await getWorker(worker);

      await registration.update();

      return registration;
    },
    [getWorker]
  );

  return { getServiceWorker };
};
