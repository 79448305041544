import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Popover,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Snackbar,
    Alert,
    Tooltip,
    Avatar,
    Box,
} from '@mui/material';
// components
import Scrollbar from '../components/scrollbar';
// icons
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// sections
import {
    TransactionListHead,
} from 'src/sections/@dashboard/transactions';
import { getTransactions } from 'src/api/transactions/getTransactions';
import TableStatusCell from 'src/components/table-status-cell';
import { isoToReadableDate } from 'src/utils/isoToReadableDate';

import { nanoid } from 'nanoid';
import AddTransactionModal from 'src/components/add-transaction-modal';
import { CategoryListToolbar } from 'src/sections/@dashboard/categories';
import { useSearchParams } from 'react-router-dom';

// ----------------------------------------------------------------------

// IDS SHOULD BE EQUAL TO PROPERTIES IN THE OBJECTS
const TABLE_HEAD = [
    { id: '_id', label: '#', align: 'left' },
    { id: 'user', label: 'Пользователь', align: 'left' },
    { id: 'status', label: 'Статус', align: 'left' },
    { id: 'paidWith', label: 'Оплачено с', align: 'left' },
    { id: 'type', label: 'Тип', align: 'left' },
    { id: 'balanceUsed', label: 'Исп. Бал.', align: 'left' },
    { id: 'total', label: 'Итого', align: 'left' },
    { id: 'createdAt', label: 'Дата', align: 'left' },
    { id: '' },
];

// ----------------------------------------------------------------------

export default function TransactionsPage() {

    const [open, setOpen] = useState(null);

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('desc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('createdAt');

    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [transactions, setTransactions] = useState(null);

    const [transactionsTotal, setTransactionsTotal] = useState(0);

    const [success, setSuccess] = useState(false);

    const [successMessage, setSuccessMessage] = useState(null);

    const [transactionAddedNotification, setTranasactionAddedNotification] = useState(null);

    const [searchParams] = useSearchParams();

    

    useEffect(() => {
        setTransactions(null);
        setSelected([]);
        const getTransactionsData = async () => {
            try {
                const data = await getTransactions(
                    rowsPerPage,
                    page,
                    orderBy,
                    order
                );
                if (data?.data?.transactions) {
                    setTransactions(data.data.transactions);
                }
                if (data?.data?.totalTransactions) {
                    setTransactionsTotal(data.data.totalTransactions);
                }
            } catch (error) {
                setTransactions(null);
                setSelected([]);
            }
        };
        getTransactionsData();
    }, [rowsPerPage, page, orderBy, order]);

    const handleOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleRequestSort = (event, property) => {
        setTransactions(null);
        setSelected([]);
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setTransactions(null);
        setSelected([]);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setTransactions(null);
        setSelected([]);
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const emptyRows =
        page > 0
            ? Math.max(0, (1 + page) * rowsPerPage - transactionsTotal)
            : 0;

    const isNotFound = false;

    // Display success message when success message is set
    useEffect(() => {
        if (successMessage) {
            handleSuccessOpen();
        }
    }, [successMessage]);

    const notifyTransaction = (message) => {
        setPage(0);
        setTranasactionAddedNotification(nanoid());
        switch (message) {
            case 'add':
                setSuccessMessage('Transaction added!');
                break;
            case 'edit':
                setSuccessMessage('Transaction edited!');
                break;
            case 'delete':
                setSuccessMessage('Transaction deleted!');
                break;
            case 'error':
                setSuccessMessage('Error occured');
                break;
            default:
                setSuccessMessage(null);
                break;
        }
    };

    const handleSuccessOpen = () => {
        setSuccess(true);
    };

    const handleSuccessClose = () => {
        setSuccess(false);
        setSuccessMessage(null);
    };

    const handleSearch = async (searchValue) => {
        setTransactions(null);
        setSelected([]);
        setPage(0);
        try {
            let data = null;
            if(searchValue.length >= 3) {
                data = await getTransactions(rowsPerPage, page, orderBy, order, searchValue)
            } else if(searchValue.length === 0) {
                data = await getTransactions(rowsPerPage, page, orderBy, order)
            }
            if (data?.data?.transactions) {
                setTransactions(data.data.transactions);
            }
            if (data?.data?.totalTransactions) {
                setTransactionsTotal(data.data.totalTransactions);
            }
        } catch (error) {
            setTransactions(null);
            setSelected([]);
        }
    }

    const [param, setParam] = useState('')

    useEffect(() => {
        const user = searchParams.get('user');
        const id = searchParams.get('id');
        if(user) {
            setParam(user)
            handleSearch(user)
        }
        if(id)
            setParam(id)
    }, [searchParams]);

    return (
        <>
            <Helmet>
                <title> Transactions | Mentup Dashboard </title>
            </Helmet>

            <Container>
                {/* Alert */}
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={success}
                    autoHideDuration={6000}
                    onClose={handleSuccessClose}
                >
                    <Alert
                        variant='filled'
                        onClose={handleSuccessClose}
                        severity={
                            successMessage === 'Error occured'
                                ? 'error'
                                : 'success'
                        }
                        sx={{ width: '100%' }}
                    >
                        {successMessage && successMessage}
                    </Alert>
                </Snackbar>

                {/* Table */}
                <Card>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mr: 3,
                    }}>
                        <CategoryListToolbar
                            handleSearchGlobal={handleSearch}
                            title={'транзакции'}
                            searchValue={param}
                        />
                        <AddTransactionModal notify={notifyTransaction} />
                    </Box>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <TransactionListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={
                                        transactions && transactions.length
                                            ? transactions.length
                                            : 0
                                    }
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {transactions?.map((transaction, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                key={index}
                                                tabIndex={-1}
                                                role='checkbox'
                                            >

                                            <TableCell align='left'>
                                                <Tooltip title="Copy id" arrow>
                                                    <Typography
                                                        sx={{
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            maxWidth: '60px',
                                                            ':hover': {
                                                                cursor: 'pointer',
                                                                textDecoration: 'underline'
                                                            }
                                                        }}
                                                        title={transaction._id || ''}
                                                        onClick={() => navigator.clipboard.writeText(transaction._id || '')}
                                                    >
                                                        {transaction._id || ''}
                                                    </Typography>
                                                </Tooltip>
                                            </TableCell>

                                            <TableCell
                                                component='th'
                                                scope='row'
                                                padding='none'
                                                sx={{
                                                    paddingLeft: '1rem',
                                                }}
                                            >
                                                <Stack
                                                    direction='row'
                                                    alignItems='center'
                                                    spacing={2}
                                                >
                                                    {transaction.user?.avatar ? (
                                                        <Tooltip title="Copy user id" arrow>
                                                            <Avatar
                                                                alt={
                                                                    transaction.user.username
                                                                        ? transaction.user.username
                                                                        : 'User avatar'
                                                                }
                                                                src={transaction.user.avatar}
                                                                onClick={() => navigator.clipboard.writeText(transaction.user._id || '')}
                                                                sx={{
                                                                    ':hover': {
                                                                        cursor: 'pointer'
                                                                    }
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    ) : (
                                                        <Tooltip title="Copy user id" arrow>
                                                            <AccountCircleIcon
                                                                sx={{
                                                                    height: '2.5rem',
                                                                    width: '2.5rem',
                                                                    ':hover': {
                                                                        cursor: 'pointer'
                                                                    }
                                                                }}
                                                                onClick={() => navigator.clipboard.writeText(transaction.user._id || '')}
                                                            />
                                                        </Tooltip>
                                                    )}

                                                <Typography
                                                    sx={{
                                                        ':hover': {
                                                            cursor: 'pointer',
                                                            textDecoration: 'underline'
                                                        }
                                                    }}
                                                    onClick={() => window.open('/dashboard/user/' + transaction.user._id, '_blank')}
                                                >
                                                    {transaction.user?.username || ''}
                                                </Typography>

                                                </Stack>
                                            </TableCell>

                                                <TableCell align='left'>
                                                    <TableStatusCell
                                                        contents={transaction.status || ''}
                                                    />
                                                </TableCell>

                                                <TableCell align='left'>
                                                    <Typography sx={{
                                                        textTransform: 'capitalize'
                                                    }}>
                                                        {transaction?.paidWith}
                                                    </Typography>
                                                </TableCell>

                                                <TableCell align='left'>
                                                    <TableStatusCell
                                                        contents={transaction.type || ''}
                                                    />
                                                </TableCell>

                                                <TableCell align='left'>
                                                    <Typography>
                                                        {`${`${transaction?.balanceUsed.toFixed(2)}`.replace(/\d(?=(\d{3})+\.)/g, "$&,")} ₽`}
                                                    </Typography>
                                                </TableCell>

                                                <TableCell align='left'>
                                                    <Typography>
                                                        {`${`${transaction?.total.toFixed(2)}`.replace(/\d(?=(\d{3})+\.)/g, "$&,")} ₽`}
                                                    </Typography>
                                                </TableCell>

                                                <TableCell align='left'>
                                                    {transaction.createdAt && (
                                                        <Typography>
                                                            {isoToReadableDate(
                                                                transaction.createdAt
                                                            )}
                                                        </Typography>
                                                    )}
                                                </TableCell>

                                            </TableRow>
                                        );
                                        }
                                    )}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{ height: 53 * emptyRows }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell
                                                align='center'
                                                colSpan={6}
                                                sx={{ py: 3 }}
                                            >
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography
                                                        variant='h6'
                                                        paragraph
                                                    >
                                                        Не найдено
                                                    </Typography>

                                                    <Typography variant='body2'>
                                                        Не найдено
                                                        &nbsp;
                                                        <strong>
                                                            &quot;
                                                            {'Search Value'}
                                                            &quot;
                                                        </strong>
                                                        .
                                                        <br /> Попробуйте проверить опечатки или корректируйте запрос.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        labelRowsPerPage="Выводить по:"
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={transactionsTotal && transactionsTotal}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            ></Popover>
        </>
    );
}
